import ServicesTemplate from "../../components/ServicesTemplate";
import { ServicesProps } from "../../service";

const config: ServicesProps = {
  title: "Managed Cloud Hosting",
  sectionHeader:
    "Ensure your services are always online, secure and accessible from anywhere on the globe.",
  sectionContent:
    "goSystem makes cloud hosting effortless. Whether you are moving on-site infrastructure onto the cloud, a web-based startup looking to get started without the management overhead, or an international corporation looking for cloud-based hosting solutions for websites, web apps or high-performance computing such as AI and neural network modelling. \n\n goSystem's years of collective expertise and dedicated academics specialising in Information Security, Artificial Intelligence and Distributed Networking means that we aren't just the most affordable, but the most knowledgeable.",
  splitTag: "Take the first steps to the cloud",
  splitHeading: "Managed Cloud Computing at the best possible price",
  splitContent:
    "Our cloud solutions not only scale perfectly to your needs but are the most affordable on the market. Our combination of both goSystem hosted cloud services and partner hosted services ensure the best reliability, scalability and security. ",
  splitImg: "CloudVPS",
  callToAction: "Harness the power of cloud computing with goSystem",
};

export default () => ServicesTemplate(config);
